<!--
 * @Author: 月魂
 * @Date: 2022-09-08 09:42:07
 * @LastEditTime: 2022-09-20 18:09:23
 * @LastEditors: 月魂
 * @Description: 主题活动详情页
 * @FilePath: \silk-road\src\views\activities\detail.vue
-->
<template>
  <div class="box">
    <van-nav-bar
      :title="getActivity.title"
      left-arrow
      @click-left="handleBack"
    />
    <img :src="getActivity.imgUrl" alt="" width="100%" class="poster">
    
    <!-- 活动介绍 -->
    <div class="activity">
      <div class="title">{{ getActivity.title }}</div>
      <div class="ele">
        <div class="item">
          <div class="item-left">{{ this.$t('params.host') }}: </div>
          <div class="item-right">{{ getActivity.host }}</div>
        </div>
        <div class="item">
          <div class="item-left">{{ this.$t('params.undertake') }}: </div>
          <div class="item-right">{{ getActivity.undertake }}</div>
        </div>
        <div class="item" v-show="getActivity.startTime">
          <div class="item-left">{{ this.$t('params.startTime') }}: </div>
          <div class="item-rig">{{ getActivity.time }}</div>
        </div>
        <div class="item">
          <div class="item-left">{{ this.$t('params.activityAddress') }}: </div>
          <div class="item-right">{{ getActivity.address }}</div>
        </div>
      </div>
      <div class="intro">
        <div class="intro-cont" v-for="(des, index) in getActivity.des" :key="index">{{ des }}</div>
      </div>
      <!-- 视频精彩剪辑 -->
      <!-- <div class="troupe" v-if="activity.clip">
        <div class="title">{{ this.$t('params.clip') }}</div>
        <video :poster="activity.imgUrl" controls style="display: block; width: 100%">
          <source :src="activity.videoUrl">
        </video>
      </div> -->
      <!-- 精彩回顾 -->
      <!-- <div class="troupe" v-show="activity.review">
        <div class="title">{{ this.$t('params.review') }}</div>
        <img v-for="(img, index) in activity.review" :key="index" :src="`http://image.wearetogether.com.cn/seven-forum-poster-${activity.id}-${img}.png`" alt="" class="review" @click="showImg(img)">
      </div> -->
    </div>
  </div>
</template>

<script>
import vue from 'vue';
import { NavBar } from 'vant'
import ImagePreview  from 'vant/lib/image-preview';
import 'vant/lib/image-preview/style';
import { handleBack } from '../../utils/util'
vue.use(ImagePreview).use(NavBar)

export default {
  name: 'ActivitiesDetail',
  components: {
  },
  data() {
    return {
      imgList: [],
      activityId: null
    }
  },
  computed: {
    getActivity() {
      let activityNode = {};
      Object.values(this.$t('activities.list')).forEach(activity => {
        if (activity.id == this.activityId) {
          activityNode = activity
        }
      })
      this.wxShare(this.$t('share.title'), activityNode.title)
      return activityNode
    }
  },
  mounted() {
    this.activityId = this.$route.params.id;
    // this.activityId = this.$route.params.id;
    // 计算精彩回顾列表
    // const pList = this.$t('forum.list')[this.activityId - 1]
    // if (pList.review && pList.review.length) {
    //   pList.review.map(item => {
    //     this.imgList.push(`http://image.wearetogether.com.cn/seven-forum-poster-${pList.id}-${item}.png`)
    //   })
    // }
  },
  methods: {
    handleBack() {
      handleBack()
    },
    showImg(position) {
      if (window.innerWidth <= 1200) {
        ImagePreview({
          images: this.imgList,
          startPosition: position - 1
        });
      }
    }
  }
}
</script>

<style lang="less" scoped>
.box {
  min-height: 100vh;
  background: #fcf8ed;
  .poster {
    display: block;
    width: 100%;
    max-width: 1200PX;
    margin: auto;
  }

  // 活动内容
  .activity {
    width: 100%;
    max-width: 1200PX;
    margin: auto;
    padding: 16px 0;
    font-family: SourceHanSerifCN-Bold;
    color: #4D4D4D;
    .title {
      padding: 0 16px;
      font-size: 21px;
      font-weight: bold;
    }
    .ele {
      padding: 0 16px;
      margin-top: 16px;
      font-size: 14px;
      display: flex;
      flex-wrap: wrap;
      font-family: SourceHanSansCN-Regular;
      .item {
        width: 100%;
        margin-top: 4px;
        display: flex;
        .item-left {
          margin-right: 8px;
        }
        .item-right {
          flex: 1;
        }
        &:first-child {
          margin-top: 0;
        }
      }
    }
    .intro {
      width: 100%;
      padding: 16px;
      color: #999;
      font-family: SourceHanSansCN-Regular;
      font-size: 14px;
      line-height: 22px;
      border-radius: 25px 25px 0 0;
      border-top: 1px solid #F5F5F5;
      overflow: hidden;
      .intro-cont {
        margin-top: 8px;
        &:first-child {
          margin-top: 0;
        }
      }
    }
    .troupe {
      .title {
        margin: 10px 0;
      }
      .review {
        display: block;
        width: 100%;
        margin-top: 8px;
      }
    }
  }
}
</style>
